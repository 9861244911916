import { mapState } from 'vuex';
import formMixin from '../services/form';
import {
    email,
    min,
    max,
    required,
} from '../services/validators';
import { setStorage } from '../services/storage';
import { store as storeCredentials } from '../services/authenticator';

export default {
    mixins: [formMixin],
    data() {
        return {
            email: '',
            password: '',
            loginValidations: {
                email: [
                    email,
                    required,
                    { validator: max, length: 150 },
                ],
                password: [
                    required,
                    { validator: min, length: 5 },
                    { validator: max, length: 150 },
                ],
            },
        };
    },
    computed: {
        ...mapState('auth', ['loggedIn']),
    },
    methods: {
        addUserLoginValidations() {
            if (!this.loggedIn) {
                this.$form.replaceValidations(this.loginValidations);
            }
        },
        getUserLoginValidations() {
            if (!this.loggedIn) {
                return this.loginValidations;
            }
            return {};
        },
        loginUser(form = this.$form) {
            return new Promise((resolve) => {
                if (form.validateFields(['email', 'password'])) {
                    if (this.loggedIn) {
                        resolve();
                        return;
                    }
                    form.submit(this.$config.endpoints.login, 'post', {
                        email: form.email.$model,
                        password: form.password.$model,
                    }, true).then((response) => {
                        if (!response) return;

                        setStorage('loginConverted', true);
                        storeCredentials(form.email.$model, form.password.$model);
                        this.$auth.setUserToken(response.data.token).then(() => {
                            resolve();
                        });
                    });
                }
            });
        },
    },
};
