
export default {
    props: {
        /**
         * A form object
         */
        form: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    watch: {
        'form.$hasError': function watchFormError(newVal) {
            if (newVal === true) {
                this.scrollToView();
            }
        },
    },
    methods: {
        scrollToView() {
            const error = document.querySelectorAll('.form-input-hint');
            if (error && error[0]) {
                error[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        },
    },
};
