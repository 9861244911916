
export default {
    Name: 'UserLoginForm',
    props: {
        /**
         * A form object
         */
        form: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            showPassword: false,
        };
    },
};
