
import { mapState } from 'vuex';
import random from '~/services/random';

export default {
    name: 'FormWrapper',
    props: {
        text: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            imageNumber: random(1, 6),
        };
    },
    computed: {
        ...mapState(['isMobile']),
        image() {
            let folder = 'small';
            if (!this.isMobile) {
                folder = 'large';
            }
            return `/images/form/${folder}/${this.imageNumber}`;
        },
    },
};

