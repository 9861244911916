
import { mapState } from 'vuex';
import { login } from '~/services/authenticator';
import linkBusinessMixin from '~/mixins/linkBusiness';
import loginUserMixin from '~/mixins/loginUser';

export default {
    name: 'Login',
    mixins: [loginUserMixin, linkBusinessMixin],
    data() {
        return {
            email: '',
            password: '',
        };
    },
    computed: mapState(['auth', 'isCordova']),
    created() {
        this.addUserLoginValidations();
    },
    async mounted() {
        this.$store.dispatch('hideNav');
        await login(this.$auth, this.$store, this.$router, 'optional', false);

        if (this.auth.loggedIn) {
            if (!this.linkBusiness()) {
                return;
            }
            this.$router.replace({ name: 'dashboard' });
        }
    },
    methods: {
        // Fudge to handle autofill not triggering input events on fields in cordova 29/7/2019
        formChange(event) {
            if (this.isCordova && event.target.type === 'password') {
                this.password = event.target.value;
            }
        },
        async submit() {
            await this.loginUser();
            if (this.$route.query.org_token) {
                await this.$axios
                    .post(this.$config.endpoints.organisationLink, {
                        org_token: this.$route.query.org_token,
                    })
                    .catch(({ response }) => {
                        const { errors } = response.data;
                        this.$store.dispatch('setFlashMessage', { message: errors.org_token[0], type: 'error' });
                    });
                await this.$auth.fetchUser();
            }
        },
    },
    validations: {},
};
