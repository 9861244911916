export default {
    methods: {
        async linkBusiness() {
            if (this.$route.query.org_token) {
                await this.$axios.post(this.$config.endpoints.organisationLink, {
                    org_token: this.$route.query.org_token,
                }).catch(({ response }) => {
                    const { errors } = response.data;
                    this.$form.$hasError = true;
                    [this.$form.$errorMessage] = errors.org_token;
                });
                if (this.$form.$hasError) {
                    return false;
                }
                await this.$auth.fetchUser();
            }

            return true;
        },
    },
};
