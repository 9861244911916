import { render, staticRenderFns } from "./login.vue?vue&type=template&id=6ff7b8d8"
import script from "./login.vue?vue&type=script&lang=js"
export * from "./login.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UserLoginForm: require('/opt/atlassian/pipelines/agent/build/components/user/UserLoginForm.vue').default,FormErrors: require('/opt/atlassian/pipelines/agent/build/components/form/FormErrors.vue').default,FormSiteButton: require('/opt/atlassian/pipelines/agent/build/components/form/SiteButton.vue').default,FormWrapper: require('/opt/atlassian/pipelines/agent/build/components/form/FormWrapper.vue').default})
